body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  overflow-x: scroll !important;
}

.header {
  color: #fff;
  padding: 0;
  background: #39cccc;
  line-height: 55px;
  height: auto;
}

.avatar {
  background-color: #39cccc;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: block;
}

.logo {
  height: 55px;
  padding: 10px 24px;
  float: left;
}

.hamburger-menu {
  height: 55px;
  padding: 0 24px;
  float: left;
  font-size: 18px;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger-menu:active {
  color: #1890ff;
  background: #fff;
}

.page-title {
  font-family: "Arial";
  font-size: 20px;
  font-weight: 300;
  float: left;
}

.login-container {
  height: 100vh;
}

.logo-row {
  height: 40%;
}

.logo-row img {
  width: 192px;
  margin-bottom: 20px;
}

.form-row {
  height: 60%;
}

.login-form {
  text-align: left;
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}

.centeredSpin {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -25px;
}

.ant-drawer-body {
  padding: 0;
}

.table-margin {
  margin-top: 24px;
}

.enum-header {
  background-color: #fff;
}

@media (max-width: 992px) {
  .page-title {
    font-weight: 400;
  }
  .iframe-Col {
    padding: 50px;
  }
}

@media (min-width: 992px) {
  .table-margin {
    margin: 24px;
  }
}

@media (min-width: 576px) {
}

.map-container {
  border-width: 0ch;
}
